import React, { useState } from 'react';
import { Brain } from 'lucide-react';
import type { AIAgent } from '../../services/aiAgentService';
import StatusIndicator from './StatusIndicator';
import AICoachHeader from './AICoachHeader';
import AICoachSpecialties from './AICoachSpecialties';
import AICoachButton from './AICoachButton';
import ConversationWidget from './ConversationWidget';

interface AICoachCardProps {
  agent: AIAgent;
}

const AICoachCard: React.FC<AICoachCardProps> = ({ agent }) => {
  const [showConversation, setShowConversation] = useState(false);

  const handleStartConversation = () => {
    setShowConversation(true);
  };

  return (
    <div 
      className={`${agent.background_color} rounded-2xl overflow-hidden shadow-lg 
        transition-all duration-500 ease-in-out transform hover:shadow-xl 
        hover:translate-y-[-2px] relative group`}
    >
      <div className="grid grid-cols-1 lg:grid-cols-3">
        {/* Image Section with overlay transition */}
        <div className="relative h-96 lg:h-auto overflow-hidden">
          <img
            src={agent.image_url}
            alt={agent.name}
            className={`absolute inset-0 w-full h-full object-cover
              transition-transform duration-500 ease-in-out
              group-hover:scale-105 ${!agent.status ? 'grayscale' : ''}`}
          />
          <StatusIndicator status={agent.status} />
          {/* Category Overlay */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="font-lobster text-4xl text-white opacity-75 text-center px-4 py-2 backdrop-blur-sm rounded-lg">
              {agent.category}
            </div>
          </div>
          <div className="absolute inset-0 bg-black/0 group-hover:bg-black/20
            transition-all duration-500 ease-in-out" />
        </div>

        {/* Content Section */}
        <div className="p-8 lg:col-span-2">
          <AICoachHeader 
            name={agent.name}
            role={agent.role}
            rating={agent.rating}
          />

          <p className="text-gray-600 mb-6 transition-colors duration-500 ease-in-out group-hover:text-gray-700">
            {agent.description}
          </p>

          <AICoachSpecialties specialties={agent.specialties} />

          <div className="flex items-center justify-between">
            <div className="text-sm text-gray-600 transition-colors duration-500 ease-in-out group-hover:text-gray-700">
              <Brain className="w-4 h-4 inline-block mr-1 transition-transform duration-500 ease-in-out group-hover:scale-110" />
              {agent.experience_count.toLocaleString()}+ {agent.experience_label}
            </div>
            <button
              onClick={handleStartConversation}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                transition-colors duration-200 flex items-center gap-2"
            >
              Start Conversation
            </button>
          </div>

          {showConversation && (
            <div className="mt-6 border-t border-gray-200 pt-6">
              <ConversationWidget agentId="6q5Sfrc2Wz80FIU1c7Om" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AICoachCard;
import React, { useState, useEffect } from 'react';
import { ArrowLeft, Loader2 } from 'lucide-react';
import { QuestionnaireService } from '../services/questionnaireService';
import type { GoalAnswer, UserAnswer } from '../lib/types';
import Button from './Button';
import UserSetupModal from './setup/UserSetupModal';

interface QuestionnaireStepProps {
  goalCategories: string[];
  onBack: () => void;
  onComplete: (answers: UserAnswer[]) => void;
}

const QuestionnaireStep: React.FC<QuestionnaireStepProps> = ({
  goalCategories,
  onBack,
  onComplete
}) => {
  const [questions, setQuestions] = useState<GoalAnswer[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<UserAnswer[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showUserSetup, setShowUserSetup] = useState(false);

  useEffect(() => {
    const loadQuestions = async () => {
      try {
        setLoading(true);
        const loadedQuestions = await QuestionnaireService.getQuestionsForCategories(goalCategories);
        setQuestions(loadedQuestions);
        setError(null);
      } catch (err) {
        setError('Failed to load questions. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    loadQuestions();
  }, [goalCategories]);

  const currentQuestion = questions[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  const handleAnswer = (answer: string) => {
    const newAnswers = [...answers, {
      question: currentQuestion.question,
      answer,
      category: currentQuestion.goal_category
    }];

    if (isLastQuestion) {
      setAnswers(newAnswers);
      setShowUserSetup(true);
    } else {
      setAnswers(newAnswers);
      setCurrentQuestionIndex(prev => prev + 1);
    }
  };

  const handleUserSetupComplete = () => {
    onComplete(answers);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <Loader2 className="w-8 h-8 text-blue-600 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-600 mb-4">{error}</p>
          <Button variant="primary" onClick={onBack}>
            Go Back
          </Button>
        </div>
      </div>
    );
  }

  if (!currentQuestion) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <p className="text-gray-600 mb-4">No questions available.</p>
          <Button variant="primary" onClick={onBack}>
            Go Back
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-3xl mx-auto">
        <button
          onClick={onBack}
          className="flex items-center text-gray-600 hover:text-gray-900 mb-8 group"
        >
          <ArrowLeft className="w-4 h-4 mr-2 transition-transform group-hover:-translate-x-1" />
          Back to Goals
        </button>

        <div className="mb-8">
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm font-medium text-gray-600">
              Question {currentQuestionIndex + 1} of {questions.length}
            </span>
            <span className="text-sm font-medium text-gray-600">
              {currentQuestion.goal_category}
            </span>
          </div>
          <div className="w-full h-2 bg-gray-200 rounded-full">
            <div
              className="h-2 bg-blue-600 rounded-full transition-all"
              style={{ width: `${((currentQuestionIndex + 1) / questions.length) * 100}%` }}
            />
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 shadow-lg">
          <h2 className="text-xl font-semibold text-gray-900 mb-6">
            {currentQuestion.question}
          </h2>

          <div className="space-y-3">
            {currentQuestion.options.map((option) => (
              <button
                key={option}
                onClick={() => handleAnswer(option)}
                className="w-full p-4 text-left rounded-lg border-2 border-gray-200 
                  hover:border-blue-400 hover:bg-blue-50 transition-all"
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        {showUserSetup && (
          <UserSetupModal
            selectedGoals={[]}
            selectedStyles={[]}
            questionnaireAnswers={answers}
            onBack={() => setShowUserSetup(false)}
            onComplete={handleUserSetupComplete}
          />
        )}
      </div>
    </div>
  );
};

export default QuestionnaireStep;
import React, { useState } from 'react';
import { Loader2 } from 'lucide-react';
import Button from '../Button';
import { OnboardingService } from '../../services/onboardingService';
import { useAuth } from '../../contexts/AuthContext';

interface UserSetupFormProps {
  onComplete: () => void;
  selectedGoals?: number[];
  questionnaireAnswers?: Record<string, any>;
}

const UserSetupForm: React.FC<UserSetupFormProps> = ({ 
  onComplete,
  selectedGoals = [],
  questionnaireAnswers = {}
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    
    try {
      console.log('Submitting with goals:', selectedGoals); // Debug log
      
      await OnboardingService.completeOnboarding({
        name,
        email: email || user?.email || '',
        selected_goals: selectedGoals,
        answers: questionnaireAnswers
      });
      
      onComplete();
    } catch (err) {
      console.error('Profile creation error:', err); // Debug log
      setError(err instanceof Error ? err.message : 'Failed to create profile');
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {error && (
        <div className="p-4 bg-red-50 text-red-600 rounded-lg text-sm">
          {error}
        </div>
      )}

      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
          Full Name
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mt-1 block w-full px-4 py-2 rounded-lg border border-gray-300 
            focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          placeholder="Enter your name"
          required
        />
      </div>

      {!user?.email && (
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email Address
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 block w-full px-4 py-2 rounded-lg border border-gray-300 
              focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="you@example.com"
            required
          />
        </div>
      )}

      <Button
        type="submit"
        variant="primary"
        disabled={isSubmitting || !name || (!user?.email && !email)}
        className="w-full"
      >
        {isSubmitting ? (
          <span className="flex items-center justify-center">
            <Loader2 className="w-5 h-5 mr-2 animate-spin" />
            Creating Profile...
          </span>
        ) : (
          'Continue to Coach Selection'
        )}
      </Button>
    </form>
  );
};

export default UserSetupForm;
import React, { useState, useEffect } from 'react';
import { ChevronRight, Sparkles } from 'lucide-react';
import CategorySection from './goal-discovery/CategorySection';
import SelectedGoals from './goal-discovery/SelectedGoals';
import FairyCompanion from './fairy/FairyCompanion';
import AdaIcon from './icons/AdaIcon';
import QuestionnaireStep from './QuestionnaireStep';
import UserSetupModal from './setup/UserSetupModal';
import CoachSelection from './CoachSelection';
import { useLeadTracking } from '../hooks/useLeadTracking';
import type { Category, Suggestion } from '../types';
import type { UserAnswer } from '../lib/types';
import { GoalService } from '../services/goalService';

interface GoalDiscoveryFlowProps {
  onMeetAIs: () => void;
}

const GoalDiscoveryFlow: React.FC<GoalDiscoveryFlowProps> = ({ onMeetAIs }) => {
  const [currentStep, setCurrentStep] = useState<'goals' | 'questionnaire' | 'coach'>('goals');
  const [expandedCategory, setExpandedCategory] = useState<number | null>(null);
  const [selectedSuggestions, setSelectedSuggestions] = useState<Suggestion[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [answers, setAnswers] = useState<UserAnswer[]>([]);
  const [showUserSetup, setShowUserSetup] = useState(false);
  const { updateGoalSelections } = useLeadTracking();

  useEffect(() => {
    const fetchGoals = async () => {
      try {
        const goals = await GoalService.getAllGoals();
        const transformedCategories = GoalService.transformGoalsToCategories(goals);
        setCategories(transformedCategories);
      } catch (error) {
        console.error('Failed to fetch goals:', error);
      }
    };

    fetchGoals();
  }, []);

  const handleDragStart = (e: React.DragEvent, id: number) => {
    e.dataTransfer.setData('categoryId', id.toString());
  };

  const handleDrop = (e: React.DragEvent, targetId: number) => {
    e.preventDefault();
    const sourceId = parseInt(e.dataTransfer.getData('categoryId'));
    if (sourceId === targetId) return;

    const newCategories = [...categories];
    const sourceIndex = categories.findIndex(c => c.id === sourceId);
    const targetIndex = categories.findIndex(c => c.id === targetId);

    const [movedCategory] = newCategories.splice(sourceIndex, 1);
    newCategories.splice(targetIndex, 0, movedCategory);

    setCategories(newCategories);
  };

  const toggleSuggestion = async (suggestion: string, categoryId: number) => {
    const newSelectedSuggestions = selectedSuggestions.some(s => s.text === suggestion)
      ? selectedSuggestions.filter(s => s.text !== suggestion)
      : [...selectedSuggestions, { text: suggestion, categoryId }];
    
    setSelectedSuggestions(newSelectedSuggestions);

    // Update lead tracking with selected goal IDs
    const selectedGoalIds = newSelectedSuggestions.map(s => s.categoryId);
    await updateGoalSelections(selectedGoalIds);
  };

  const getCategoryCount = (categoryId: number) => {
    return selectedSuggestions.filter(s => s.categoryId === categoryId).length;
  };

  const handleQuestionnaireComplete = (questionnaireAnswers: UserAnswer[]) => {
    setAnswers(questionnaireAnswers);
    setShowUserSetup(true);
  };

  const handleUserSetupComplete = () => {
    setShowUserSetup(false);
    setCurrentStep('coach');
  };

  if (currentStep === 'goals') {
    return (
      <div className="min-h-screen bg-gray-50 text-gray-900 flex flex-col" data-page="goals">
        <FairyCompanion />
        <div className="flex-1 max-w-5xl mx-auto w-full p-4 flex flex-col">
          <div className="sticky top-0 bg-blue-50/80 backdrop-blur-sm z-10 pb-6 px-6 -mx-4 pt-4">
            <div className="flex items-start gap-3 mb-6">
              <div className="bg-blue-100 p-2 rounded-full">
                <AdaIcon className="w-6 h-6 text-blue-600" />
              </div>
              <div className="flex-1">
                <h2 className="text-xl font-display font-semibold mb-2">What would you like to change or improve?</h2>
                <p className="text-gray-600 text-sm">Select multiple goals from any category. Drag to reorder categories based on importance to you.</p>
              </div>
            </div>

            <SelectedGoals
              selectedSuggestions={selectedSuggestions}
              categories={categories}
              onClear={() => {
                setSelectedSuggestions([]);
                updateGoalSelections([]);
              }}
            />

            <button 
              onClick={() => setCurrentStep('questionnaire')}
              className={`w-full py-3 px-4 rounded-xl flex items-center justify-center gap-2 transition-all mb-6
                ${selectedSuggestions.length > 0 
                  ? 'bg-blue-600 hover:bg-blue-700 text-white' 
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'}`}
              disabled={selectedSuggestions.length === 0}
            >
              Continue with {selectedSuggestions.length} goals
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>

          <div className="flex-1 overflow-y-auto pt-4">
            <div className="space-y-3">
              <div className="flex items-center gap-2 text-gray-600 text-sm">
                <Sparkles className="w-4 h-4" />
                <span>Select from suggested goals:</span>
              </div>
              <div className="space-y-2">
                {categories.map((category) => (
                  <CategorySection
                    key={category.id}
                    category={category}
                    isExpanded={expandedCategory === category.id}
                    selectionCount={getCategoryCount(category.id)}
                    onToggleExpand={setExpandedCategory}
                    onToggleSuggestion={toggleSuggestion}
                    selectedSuggestions={selectedSuggestions}
                    onDragStart={handleDragStart}
                    onDrop={handleDrop}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (currentStep === 'questionnaire') {
    return (
      <div data-page="questionnaire">
        <QuestionnaireStep
          goalCategories={Array.from(new Set(selectedSuggestions.map(s => 
            categories.find(c => c.id === s.categoryId)?.title || ''
          )))}
          onBack={() => setCurrentStep('goals')}
          onComplete={handleQuestionnaireComplete}
        />
      </div>
    );
  }

  if (currentStep === 'coach') {
    return (
      <div data-page="coach">
        <CoachSelection
          onBack={() => setCurrentStep('questionnaire')}
          onSelect={() => {}}
        />
      </div>
    );
  }

  return null;
};

export default GoalDiscoveryFlow;
import React from 'react';
import { Check } from 'lucide-react';
import type { UserAnswer } from '../../lib/types';

interface UserSetupSummaryProps {
  selectedGoals: string[];
  selectedStyles: string[];
  questionnaireAnswers: UserAnswer[];
}

const UserSetupSummary: React.FC<UserSetupSummaryProps> = ({
  selectedGoals,
  selectedStyles,
  questionnaireAnswers
}) => {
  return (
    <div className="bg-gray-50 rounded-xl p-6">
      <h3 className="font-semibold text-gray-900 mb-4">Your Profile Summary</h3>
      
      <div className="space-y-6">
        <div>
          <h4 className="text-sm font-medium text-gray-700 mb-2">Selected Goals</h4>
          <ul className="space-y-2">
            {selectedGoals.map((goal, index) => (
              <li key={index} className="flex items-center text-sm text-gray-600">
                <Check className="w-4 h-4 text-blue-600 mr-2 flex-shrink-0" />
                {goal}
              </li>
            ))}
          </ul>
        </div>

        {selectedStyles.length > 0 && (
          <div>
            <h4 className="text-sm font-medium text-gray-700 mb-2">Learning Preferences</h4>
            <ul className="space-y-2">
              {selectedStyles.map((style, index) => (
                <li key={index} className="flex items-center text-sm text-gray-600">
                  <Check className="w-4 h-4 text-blue-600 mr-2 flex-shrink-0" />
                  {style}
                </li>
              ))}
            </ul>
          </div>
        )}

        <div>
          <h4 className="text-sm font-medium text-gray-700 mb-2">Your Responses</h4>
          <ul className="space-y-2">
            {questionnaireAnswers.map((answer, index) => (
              <li key={index} className="text-sm">
                <span className="text-gray-500">{answer.question}:</span>
                <br />
                <span className="text-gray-900 font-medium">
                  {Array.isArray(answer.answer) ? answer.answer.join(', ') : answer.answer}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserSetupSummary;
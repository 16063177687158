import React from 'react';

interface HeroBackgroundProps {
  currentImage: string;
  nextImage: string;
}

const HeroBackground: React.FC<HeroBackgroundProps> = ({ currentImage, nextImage }) => (
  <>
    {/* Current Image */}
    <div className="absolute inset-0 transition-opacity duration-1000 ease-in-out">
      <div className="absolute inset-0 bg-gradient-to-r from-blue-900/30 to-purple-900/30 mix-blend-multiply" />
      <img 
        src={currentImage}
        alt="Professional coaching session"
        className="w-full h-full object-cover"
      />
    </div>

    {/* Next Image (preloaded) */}
    <div className="absolute inset-0 transition-opacity duration-1000 ease-in-out opacity-0">
      <div className="absolute inset-0 bg-gradient-to-r from-blue-900/30 to-purple-900/30 mix-blend-multiply" />
      <img 
        src={nextImage}
        alt="Professional coaching session"
        className="w-full h-full object-cover"
      />
    </div>

    {/* Additional gamma overlay */}
    <div className="absolute inset-0 bg-gradient-to-b from-black/10 via-transparent to-black/20" />
  </>
);

export default HeroBackground;
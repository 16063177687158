import React from 'react';
import { ChevronDown, GripVertical, Check } from 'lucide-react';
import type { Category, Suggestion } from '../../types';

interface CategorySectionProps {
  category: Category;
  isExpanded: boolean;
  selectionCount: number;
  onToggleExpand: (id: number) => void;
  onToggleSuggestion: (suggestion: string, categoryId: number) => void;
  selectedSuggestions: Suggestion[];
  onDragStart: (e: React.DragEvent, id: number) => void;
  onDrop: (e: React.DragEvent, id: number) => void;
}

const CategorySection: React.FC<CategorySectionProps> = ({
  category,
  isExpanded,
  selectionCount,
  onToggleExpand,
  onToggleSuggestion,
  selectedSuggestions,
  onDragStart,
  onDrop,
}) => {
  return (
    <div
      draggable
      onDragStart={(e) => onDragStart(e, category.id)}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => onDrop(e, category.id)}
      className="space-y-2 cursor-move"
    >
      <div className={`w-full bg-white rounded-xl transition-all duration-300 group border border-gray-200 
        ${isExpanded ? 'shadow-lg' : 'hover:shadow-md'}`}
      >
        <div className="p-4 flex items-center gap-3">
          <div className="cursor-move p-1 hover:bg-gray-50 rounded group-hover:text-gray-600">
            <GripVertical className="w-4 h-4 text-gray-400" />
          </div>

          <div className={`w-6 h-6 rounded-full flex items-center justify-center text-sm
            ${selectionCount > 0 ? 'bg-blue-600 text-white' : 'bg-gray-100 text-gray-500'}`}>
            {selectionCount}
          </div>

          <button
            onClick={() => onToggleExpand(category.id)}
            className="flex-1 flex items-center gap-3"
          >
            <div className="p-2 bg-gray-50 rounded-lg group-hover:bg-gray-100">
              <category.icon className={`w-4 h-4 ${category.color}`} />
            </div>
            <span className="text-gray-700 group-hover:text-gray-900 font-medium">
              {category.title}
            </span>
          </button>

          <ChevronDown 
            className={`w-5 h-5 text-gray-400 transition-transform ${
              isExpanded ? 'rotate-180' : ''
            }`}
          />
        </div>
        
        {isExpanded && (
          <div className="px-4 pb-4 space-y-2 border-t border-gray-100 mt-2 pt-3">
            {category.suggestions.map((suggestion, index) => {
              const isSelected = selectedSuggestions.some(s => s.text === suggestion);
              return (
                <button
                  key={index}
                  onClick={() => onToggleSuggestion(suggestion, category.id)}
                  className={`w-full rounded-xl p-3 text-left transition-all text-sm flex items-center gap-3
                    ${isSelected 
                      ? 'bg-blue-50 text-blue-700 hover:bg-blue-100' 
                      : 'bg-gray-50 text-gray-600 hover:bg-gray-100 hover:text-gray-900'}`}
                >
                  <div className={`w-5 h-5 rounded-full border flex items-center justify-center flex-shrink-0
                    ${isSelected 
                      ? 'border-blue-600 bg-blue-600' 
                      : 'border-gray-300'}`}
                  >
                    {isSelected && <Check className="w-3 h-3 text-white" />}
                  </div>
                  {suggestion}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategorySection;
import { supabase } from '../lib/supabase';
import type { CreateUserDTO, User } from '../lib/types';

export class UserService {
  static async createUser(userData: CreateUserDTO): Promise<User> {
    try {
      const { data, error } = await supabase
        .from('users')
        .insert([{
          name: userData.name,
          email: userData.email,
          selected_goals: userData.selected_goals,
          answers: userData.answers || {},
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }])
        .select()
        .single();

      if (error) {
        console.error('Error creating user:', error);
        throw new Error(`Failed to create user: ${error.message}`);
      }

      if (!data) {
        throw new Error('No data returned from user creation');
      }

      return data;
    } catch (err) {
      console.error('Error in createUser:', err);
      throw err;
    }
  }

  static async updateUser(id: string, userData: Partial<CreateUserDTO>): Promise<User> {
    try {
      const { data, error } = await supabase
        .from('users')
        .update({
          ...userData,
          updated_at: new Date().toISOString()
        })
        .eq('id', id)
        .select()
        .single();

      if (error) {
        console.error('Error updating user:', error);
        throw new Error(`Failed to update user: ${error.message}`);
      }

      if (!data) {
        throw new Error('No data returned from user update');
      }

      return data;
    } catch (err) {
      console.error('Error in updateUser:', err);
      throw err;
    }
  }

  static async deleteUser(id: string): Promise<void> {
    try {
      const { error } = await supabase
        .from('users')
        .delete()
        .eq('id', id);

      if (error) {
        console.error('Error deleting user:', error);
        throw new Error(`Failed to delete user: ${error.message}`);
      }
    } catch (err) {
      console.error('Error in deleteUser:', err);
      throw err;
    }
  }

  static async getUserById(id: string): Promise<User | null> {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', id)
        .single();

      if (error) {
        console.error('Error fetching user:', error);
        throw new Error(`Failed to fetch user: ${error.message}`);
      }

      return data;
    } catch (err) {
      console.error('Error in getUserById:', err);
      throw err;
    }
  }

  static async getAllUsers(): Promise<User[]> {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching users:', error);
        throw new Error(`Failed to fetch users: ${error.message}`);
      }

      return data || [];
    } catch (err) {
      console.error('Error in getAllUsers:', err);
      throw err;
    }
  }
}
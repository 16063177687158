import React from 'react';
import SpecialtyTag from './SpecialtyTag';

interface AICoachSpecialtiesProps {
  specialties: string[];
}

const AICoachSpecialties: React.FC<AICoachSpecialtiesProps> = ({ specialties }) => (
  <div className="mb-6">
    <h3 className="font-semibold text-gray-900 mb-3 transition-colors duration-500 ease-in-out group-hover:text-gray-800">
      Specialties
    </h3>
    <div className="flex flex-wrap gap-2">
      {specialties.map((specialty, index) => (
        <SpecialtyTag key={index} specialty={specialty} />
      ))}
    </div>
  </div>
);

export default AICoachSpecialties;
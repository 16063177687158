import React, { useEffect, useState } from 'react';
import { supabase } from '../lib/supabase';
import { AlertCircle, RefreshCw, ChevronDown, ChevronUp } from 'lucide-react';

interface Goal {
  id: number;
  category: string;
  description: string;
}

interface CategoryGoals {
  [category: string]: Goal[];
}

const GoalCategories: React.FC = () => {
  const [categoryGoals, setCategoryGoals] = useState<CategoryGoals>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);

  const fetchGoals = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const { data, error } = await supabase
        .from('goals')
        .select('*')
        .order('category, description');

      if (error) throw error;

      // Group goals by category
      const grouped = (data as Goal[]).reduce((acc, goal) => {
        if (!acc[goal.category]) {
          acc[goal.category] = [];
        }
        acc[goal.category].push(goal);
        return acc;
      }, {} as CategoryGoals);

      setCategoryGoals(grouped);
    } catch (err) {
      console.error('Error fetching goals:', err);
      setError(err instanceof Error ? err.message : 'Failed to fetch goals');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGoals();
  }, []);

  const toggleCategory = (category: string) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="p-4 border-b border-gray-200 flex justify-between items-center">
        <h2 className="text-lg font-semibold text-gray-900">Goal Categories</h2>
        <button
          onClick={fetchGoals}
          className="p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
          title="Refresh goals"
        >
          <RefreshCw className="w-4 h-4" />
        </button>
      </div>

      {loading ? (
        <div className="flex items-center justify-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      ) : error ? (
        <div className="p-4 bg-red-50 flex items-center gap-2 text-red-600">
          <AlertCircle className="w-5 h-5 flex-shrink-0" />
          <span className="text-sm">{error}</span>
        </div>
      ) : (
        <div className="divide-y divide-gray-200">
          {Object.entries(categoryGoals).map(([category, goals]) => (
            <div key={category} className="group">
              <button
                onClick={() => toggleCategory(category)}
                className="w-full p-4 hover:bg-gray-50 flex items-center justify-between"
              >
                <span className="text-sm font-medium text-gray-900">{category}</span>
                <div className="flex items-center gap-2 text-gray-500">
                  <span className="text-sm">{goals.length} goals</span>
                  {expandedCategory === category ? (
                    <ChevronUp className="w-4 h-4" />
                  ) : (
                    <ChevronDown className="w-4 h-4" />
                  )}
                </div>
              </button>
              {expandedCategory === category && (
                <div className="bg-gray-50 px-4 py-2">
                  <ul className="space-y-2">
                    {goals.map((goal) => (
                      <li key={goal.id} className="text-sm text-gray-600 pl-4 py-1 border-l-2 border-gray-200">
                        {goal.description}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
          {Object.keys(categoryGoals).length === 0 && (
            <div className="p-4 text-center text-gray-500 text-sm">
              No goals found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GoalCategories;
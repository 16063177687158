import React from 'react';
import { ArrowRight, Brain, Sparkles } from 'lucide-react';
import Button from '../Button';
import Heading from '../typography/Heading';

interface HeroContentProps {
  roleText: string;
  isTyping: boolean;
  onGetStarted: () => void;
  onMeetAIs: () => void;
}

const HeroContent: React.FC<HeroContentProps> = ({ 
  roleText, 
  isTyping, 
  onGetStarted, 
  onMeetAIs 
}) => (
  <div className="relative w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="max-w-md bg-gray-900/70 backdrop-blur-sm rounded-2xl p-8 md:p-10">
      <Heading level={1} className="text-white/90 mb-8">
        Your Personal<br />
        AI <span className="relative inline-block min-w-[3ch] border-b-2 border-white/90">
          {roleText}
          {isTyping && (
            <span className="absolute -right-1 top-0 h-full w-0.5 bg-white/90 animate-blink" />
          )}
        </span>
      </Heading>
      <p className="text-lg text-blue-100/80 mb-10 leading-relaxed">
        Transform your life with personalized guidance powered by advanced AI. Set goals, track progress, and achieve your dreams.
      </p>
      <div className="flex flex-col gap-4">
        <Button 
          variant="primary" 
          size="lg" 
          onClick={onGetStarted} 
          className="w-full justify-center"
        >
          Start Your Journey
          <ArrowRight className="ml-2 w-5 h-5" />
        </Button>
        <Button 
          variant="outline" 
          size="lg" 
          onClick={onMeetAIs} 
          className="w-full justify-center"
        >
          Meet Our AIs
          <Brain className="ml-2 w-5 h-5" />
        </Button>
      </div>
    </div>
  </div>
);

export default HeroContent;
import { supabase } from '../lib/supabase';

export interface AIAgent {
  id: string;
  name: string;
  role: string;
  category: string;
  image_url: string;
  specialties: string[];
  description: string;
  rating: number;
  experience_count: number;
  experience_label: string;
  background_color: string;
  priority: number;
  status: boolean;
  elevenlabs_widget?: string;
}

export class AIAgentService {
  static async getAllAgents(): Promise<AIAgent[]> {
    try {
      const { data, error } = await supabase
        .from('ai_agents')
        .select('*')
        .eq('is_active', true)
        .order('status', { ascending: false })
        .order('priority');

      if (error) {
        throw error;
      }

      return data || [];
    } catch (err) {
      console.error('Error fetching AI agents:', err);
      throw err;
    }
  }

  static async getAgentById(id: string): Promise<AIAgent | null> {
    try {
      const { data, error } = await supabase
        .from('ai_agents')
        .select('*')
        .eq('id', id)
        .eq('is_active', true)
        .single();

      if (error) {
        throw error;
      }

      return data;
    } catch (err) {
      console.error('Error fetching AI agent:', err);
      throw err;
    }
  }

  static async updateAgent(agent: AIAgent): Promise<AIAgent> {
    try {
      const { data, error } = await supabase
        .from('ai_agents')
        .update({
          name: agent.name,
          role: agent.role,
          category: agent.category,
          image_url: agent.image_url,
          specialties: agent.specialties,
          description: agent.description,
          rating: agent.rating,
          experience_count: agent.experience_count,
          experience_label: agent.experience_label,
          background_color: agent.background_color,
          status: agent.status,
          elevenlabs_widget: agent.elevenlabs_widget,
          updated_at: new Date().toISOString()
        })
        .eq('id', agent.id)
        .select()
        .single();

      if (error) {
        throw error;
      }

      return data;
    } catch (err) {
      console.error('Error updating AI agent:', err);
      throw err;
    }
  }
}
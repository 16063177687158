import React from 'react';
import { X } from 'lucide-react';
import { useLocation } from '../../hooks/useLocation';

interface HelpDialogProps {
  onClose: () => void;
}

const HelpDialog: React.FC<HelpDialogProps> = ({ onClose }) => {
  const location = useLocation();
  
  const helpContent = {
    '/': {
      title: 'Welcome to liveguide.ai',
      sections: [
        {
          title: 'Getting Started',
          items: [
            'Click "Start Your Journey" to begin your personalized coaching experience',
            'Use "Meet Our AIs" to learn about our AI coaches and their specialties',
            'Watch the typing animation to see different AI roles available'
          ]
        }
      ]
    },
    '/goals': {
      title: 'Goal Selection',
      sections: [
        {
          title: 'Selecting Goals',
          items: [
            'Click on categories to expand them',
            'Select multiple goals across different categories',
            'Drag categories to reorder them by priority'
          ]
        },
        {
          title: 'Navigation',
          items: [
            'Use the continue button when you\'ve selected your goals',
            'Selected goals appear at the top for easy review'
          ]
        }
      ]
    },
    '/questionnaire': {
      title: 'Questionnaire',
      sections: [
        {
          title: 'Answering Questions',
          items: [
            'Choose the most appropriate answer for each question',
            'Some questions allow multiple selections',
            'Progress is shown at the top of the page'
          ]
        }
      ]
    },
    '/coach': {
      title: 'Coach Selection',
      sections: [
        {
          title: 'Choosing Your Coach',
          items: [
            'Review each coach\'s specialties and experience',
            'Green status indicator shows available coaches',
            'Click on a coach card to select them',
            'Some coaches offer instant chat through the ElevenLabs widget'
          ]
        }
      ]
    }
  };

  const currentHelp = helpContent[location as keyof typeof helpContent] || helpContent['/'];

  return (
    <div className="fixed inset-0 z-[60] flex items-center justify-center">
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={onClose} />
      <div className="relative w-full max-w-2xl bg-white rounded-2xl shadow-2xl m-4 overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
          <h2 className="text-xl font-display font-semibold text-gray-900">
            {currentHelp.title}
          </h2>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        
        <div className="p-6 max-h-[70vh] overflow-y-auto">
          {currentHelp.sections.map((section, index) => (
            <div key={index} className="mb-6 last:mb-0">
              <h3 className="text-lg font-semibold text-gray-900 mb-3">
                {section.title}
              </h3>
              <ul className="space-y-2">
                {section.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="flex items-start gap-2 text-gray-600">
                    <span className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-2 flex-shrink-0" />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HelpDialog;
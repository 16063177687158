import React from 'react';

interface SpecialtyTagProps {
  specialty: string;
}

const SpecialtyTag: React.FC<SpecialtyTagProps> = ({ specialty }) => (
  <span
    className="px-3 py-1 bg-white text-gray-600 rounded-full text-sm font-medium
      transition-all duration-500 ease-in-out transform
      group-hover:bg-gray-900 group-hover:text-white"
  >
    {specialty}
  </span>
);

export default SpecialtyTag;
import React from 'react';
import { Star } from 'lucide-react';

interface AICoachHeaderProps {
  name: string;
  role: string;
  rating: number;
}

const AICoachHeader: React.FC<AICoachHeaderProps> = ({ name, role, rating }) => (
  <div className="flex items-center justify-between mb-4">
    <div>
      <h2 className="text-2xl font-bold text-gray-900 transition-colors duration-500 ease-in-out group-hover:text-gray-800">
        {name}
      </h2>
      <p className="text-lg text-gray-600 transition-colors duration-500 ease-in-out group-hover:text-gray-700">
        {role}
      </p>
    </div>
    <div className="flex items-center">
      <Star className="w-5 h-5 text-yellow-400 fill-current transition-transform duration-500 ease-in-out group-hover:scale-110" />
      <span className="ml-1 font-semibold">{rating}</span>
    </div>
  </div>
);

export default AICoachHeader;
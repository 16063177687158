import React, { useState, useEffect } from 'react';
import { Sparkles } from 'lucide-react';
import HelpDialog from '../help/HelpDialog';

const FairyCompanion: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [canHide, setCanHide] = useState(false);
  
  useEffect(() => {
    let inactivityTimer: NodeJS.Timeout;
    let hoverTimer: NodeJS.Timeout;
    let minDisplayTimer: NodeJS.Timeout;

    const startHovering = () => {
      const randomMovement = () => {
        setPosition(prev => ({
          x: prev.x + (Math.random() - 0.5) * 10,
          y: prev.y + (Math.random() - 0.5) * 10
        }));
        hoverTimer = setTimeout(randomMovement, 1000);
      };
      randomMovement();
    };

    const handleActivity = () => {
      if (showHelp) return; // Don't hide fairy when help is shown
      
      clearTimeout(inactivityTimer);
      clearTimeout(hoverTimer);
      
      if (canHide) {
        setIsVisible(false);
        setCanHide(false);
      }
      
      inactivityTimer = setTimeout(() => {
        setIsVisible(true);
        startHovering();
        
        // Set minimum display duration
        minDisplayTimer = setTimeout(() => {
          setCanHide(true);
        }, 5000); // Minimum 5 seconds display time
      }, 10000); // Show after 10 seconds of inactivity
    };

    // Add event listeners for user activity
    const events = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchstart'];
    events.forEach(event => document.addEventListener(event, handleActivity));

    // Initial timer
    handleActivity();

    return () => {
      events.forEach(event => document.removeEventListener(event, handleActivity));
      clearTimeout(inactivityTimer);
      clearTimeout(hoverTimer);
      clearTimeout(minDisplayTimer);
    };
  }, [showHelp, canHide]);

  const handleFairyClick = () => {
    setShowHelp(true);
    setIsVisible(false);
    setCanHide(false);
  };

  if (!isVisible && !showHelp) return null;

  return (
    <>
      <button
        onClick={handleFairyClick}
        className="fixed top-4 right-4 transition-all duration-1000 ease-in-out z-50 cursor-pointer"
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
      >
        <div className="relative w-12 h-12">
          {/* Fairy body */}
          <div className="absolute inset-0 bg-blue-400 rounded-full opacity-20 animate-pulse"></div>
          <div className="absolute inset-2 bg-blue-300 rounded-full opacity-30 animate-pulse delay-75"></div>
          <div className="absolute inset-3 bg-white rounded-full shadow-lg flex items-center justify-center">
            <Sparkles className="w-4 h-4 text-blue-500 animate-spin-slow" />
          </div>
          
          {/* Wings */}
          <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-4 h-6 bg-white/50 rounded-l-full animate-flutter"></div>
          <div className="absolute -right-2 top-1/2 -translate-y-1/2 w-4 h-6 bg-white/50 rounded-r-full animate-flutter delay-100"></div>
          
          {/* Sparkle trail */}
          <div className="absolute -z-10 w-2 h-2 rounded-full bg-blue-200 opacity-50 animate-trail-1"></div>
          <div className="absolute -z-10 w-2 h-2 rounded-full bg-blue-200 opacity-50 animate-trail-2"></div>
          <div className="absolute -z-10 w-2 h-2 rounded-full bg-blue-200 opacity-50 animate-trail-3"></div>
        </div>
      </button>

      {showHelp && <HelpDialog onClose={() => setShowHelp(false)} />}
    </>
  );
};

export default FairyCompanion;
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: 'pkce'
  },
  global: {
    headers: {
      'X-Client-Info': 'liveguide-ai'
    }
  }
});

export interface SupabaseResource {
  id: string;
  name: string;
  type: 'table' | 'function' | 'bucket';
  schema: string;
  created_at: string;
}

export async function listResources(): Promise<SupabaseResource[]> {
  try {
    const { data: tables, error: tablesError } = await supabase
      .from('goals')
      .select('id, category')
      .limit(1);

    if (tablesError) {
      console.error('Error checking goals table:', tablesError);
      throw tablesError;
    }

    // If we can query the goals table, we know it exists
    const resources: SupabaseResource[] = [{
      id: 'table_goals',
      name: 'goals',
      type: 'table',
      schema: 'public',
      created_at: new Date().toISOString()
    }];

    return resources;
  } catch (error) {
    console.error('Error listing resources:', error);
    return [];
  }
}
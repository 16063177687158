import { modern } from './modern';
import { minimal } from './minimal';
import { classic } from './classic';

export const templates = {
  modern,
  minimal,
  classic
} as const;

export type TemplateType = keyof typeof templates;
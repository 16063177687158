import { supabase } from '../lib/supabase';
import type { GoalAnswer } from '../lib/types';

export class QuestionnaireService {
  static async getQuestionsForCategories(categories: string[]): Promise<GoalAnswer[]> {
    try {
      const { data, error } = await supabase
        .from('goal_answers_template')
        .select('*')
        .in('goal_category', categories)
        .order('priority', { ascending: true });

      if (error) {
        throw error;
      }

      return data.map(row => ({
        ...row,
        options: row.options.split(',').map(opt => opt.trim())
      }));
    } catch (err) {
      console.error('Error fetching questions:', err);
      throw err;
    }
  }
}
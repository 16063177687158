import React, { useState, useEffect } from 'react';
import { ArrowLeft, Loader2 } from 'lucide-react';
import { AIAgentService, type AIAgent } from '../services/aiAgentService';
import AICoachCard from './ai/AICoachCard';
import Button from './Button';

interface CoachSelectionProps {
  onBack: () => void;
  onSelect: (coachId: string) => void;
}

const CoachSelection: React.FC<CoachSelectionProps> = ({ onBack, onSelect }) => {
  const [agents, setAgents] = useState<AIAgent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedAgent, setSelectedAgent] = useState<string | null>(null);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        setLoading(true);
        const data = await AIAgentService.getAllAgents();
        setAgents(data);
        setError(null);
      } catch (err) {
        setError('Failed to load AI coaches. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, []);

  const handleContinue = () => {
    if (selectedAgent) {
      onSelect(selectedAgent);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="flex items-center space-x-2">
          <Loader2 className="w-6 h-6 text-blue-600 animate-spin" />
          <span className="text-gray-600">Loading AI coaches...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-600 mb-4">{error}</p>
          <Button variant="primary" size="sm" onClick={() => window.location.reload()}>
            Try Again
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <button
          onClick={onBack}
          className="flex items-center text-gray-600 hover:text-gray-900 mb-8 group"
        >
          <ArrowLeft className="w-4 h-4 mr-2 transition-transform group-hover:-translate-x-1" />
          Back to Profile
        </button>

        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Choose Your AI Coach
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Select the coach that best matches your goals and preferences.
          </p>
        </div>

        <div className="space-y-12 mb-12">
          {agents.map((agent) => (
            <div
              key={agent.id}
              onClick={() => setSelectedAgent(agent.id)}
              className={`cursor-pointer transition-all duration-300 ${
                selectedAgent === agent.id ? 'ring-2 ring-blue-500 rounded-2xl' : ''
              }`}
            >
              <AICoachCard agent={agent} />
            </div>
          ))}
        </div>

        <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4">
          <div className="max-w-7xl mx-auto">
            <Button
              variant="primary"
              size="lg"
              onClick={handleContinue}
              disabled={!selectedAgent}
              className="w-full sm:w-auto"
            >
              Continue with Selected Coach
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachSelection;
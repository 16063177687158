import React, { useState } from 'react';
import GoalDiscoveryFlow from './components/GoalDiscoveryFlow';
import Homepage from './components/Homepage';
import AdminDashboard from './components/AdminDashboard';
import MeetTheAIs from './components/MeetTheAIs';
import { TemplateProvider } from './contexts/TemplateContext';
import { AuthProvider } from './contexts/AuthContext';
import { useAuth } from './contexts/AuthContext';

function AppContent() {
  const [currentView, setCurrentView] = useState<'home' | 'goals' | 'ai' | 'admin'>('home');
  const { user, isLoading } = useAuth();

  // Simple admin toggle with keyboard shortcut (Ctrl + Shift + A)
  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.shiftKey && e.key === 'A') {
        setCurrentView('admin');
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  // Only require authentication for admin dashboard
  if (currentView === 'admin' && !user) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4">
        <div className="w-full max-w-md">
          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-gray-900">Admin Access Required</h2>
            <p className="mt-2 text-gray-600">Please sign in to access the admin dashboard.</p>
          </div>
          <button
            onClick={() => setCurrentView('home')}
            className="w-full px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-700"
          >
            Return to Home
          </button>
        </div>
      </div>
    );
  }

  const renderContent = () => {
    switch (currentView) {
      case 'admin':
        return <AdminDashboard />;
      case 'ai':
        return <MeetTheAIs />;
      case 'goals':
        return (
          <GoalDiscoveryFlow 
            onMeetAIs={() => setCurrentView('ai')}
          />
        );
      default:
        return (
          <Homepage 
            onGetStarted={() => setCurrentView('goals')} 
            onMeetAIs={() => setCurrentView('ai')} 
          />
        );
    }
  };

  return renderContent();
}

function App() {
  return (
    <TemplateProvider>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </TemplateProvider>
  );
}

export default App;
export const modern = {
  button: {
    primary: `inline-flex items-center justify-center font-medium transition-all duration-200
      bg-gradient-to-r from-blue-600 to-blue-700 text-white 
      hover:from-blue-700 hover:to-blue-800 
      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 
      active:from-blue-800 active:to-blue-900
      disabled:opacity-50 disabled:cursor-not-allowed
      rounded-lg shadow-md hover:shadow-lg`,
    secondary: `inline-flex items-center justify-center font-medium transition-all duration-200
      bg-gray-900 text-white
      hover:bg-gray-800 
      focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2
      active:bg-gray-950
      disabled:opacity-50 disabled:cursor-not-allowed
      rounded-lg shadow-md hover:shadow-lg`,
    outline: `inline-flex items-center justify-center font-medium transition-all duration-200
      border-2 border-white text-white
      hover:bg-white/10 
      focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2
      active:bg-white/20
      disabled:opacity-50 disabled:cursor-not-allowed
      rounded-lg`
  },
  card: {
    wrapper: 'bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden',
    header: 'px-6 py-4 border-b border-gray-100',
    body: 'px-6 py-4',
    footer: 'px-6 py-4 bg-gray-50'
  },
  input: {
    base: 'w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200',
    error: 'border-red-300 focus:ring-red-500',
    success: 'border-green-300 focus:ring-green-500'
  }
};
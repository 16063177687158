import React from 'react';

interface StatusIndicatorProps {
  status: boolean;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status }) => (
  <div className="absolute bottom-4 right-4 z-10">
    <div 
      className={`w-3 h-3 rounded-full ${
        status 
          ? 'bg-green-500 animate-pulse shadow-lg shadow-green-500/50' 
          : 'bg-red-500 shadow-lg shadow-red-500/50'
      }`} 
    />
  </div>
);

export default StatusIndicator;
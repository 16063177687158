import React, { useEffect, useState } from 'react';
import { Loader2 } from 'lucide-react';
import { AIAgentService, type AIAgent } from '../services/aiAgentService';
import Button from './Button';
import AICoachCard from './ai/AICoachCard';

const MeetTheAIs: React.FC = () => {
  const [agents, setAgents] = useState<AIAgent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        setLoading(true);
        const data = await AIAgentService.getAllAgents();
        setAgents(data);
        setError(null);
      } catch (err) {
        setError('Failed to load AI agents. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="flex items-center space-x-2">
          <Loader2 className="w-6 h-6 text-blue-600 animate-spin" />
          <span className="text-gray-600">Loading AI agents...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-600 mb-4">{error}</p>
          <Button variant="primary" size="sm" onClick={() => window.location.reload()}>
            Try Again
          </Button>
        </div>
      </div>
    );
  }

  const onlineAgents = agents.filter(agent => agent.status);
  const offlineAgents = agents.filter(agent => !agent.status);

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Meet Our AI Coaches
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Each of our AI coaches brings unique expertise and perspective to help you achieve your goals.
          </p>
        </div>

        {/* Online AI Coaches */}
        {onlineAgents.length > 0 && (
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">Available Now</h2>
            <div className="space-y-8">
              {onlineAgents.map((agent) => (
                <AICoachCard key={agent.id} agent={agent} />
              ))}
            </div>
          </div>
        )}

        {/* Offline AI Coaches */}
        {offlineAgents.length > 0 && (
          <div className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-900 mb-6">Currently Offline</h2>
            <div className="space-y-8">
              {offlineAgents.map((agent) => (
                <AICoachCard key={agent.id} agent={agent} />
              ))}
            </div>
          </div>
        )}

        {/* CTA Section */}
        <div className="mt-16 text-center">
          <Button variant="primary" size="lg" onClick={() => window.history.back()}>
            Back to Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MeetTheAIs;
import { useState, useEffect, useCallback } from 'react';

interface TypewriterOptions {
  phrases: string[];
  minSpeed?: number;
  maxSpeed?: number;
  startDelay?: number;
  holdDuration?: number;
}

export const useTypewriter = ({ 
  phrases,
  minSpeed = 50, 
  maxSpeed = 150,
  startDelay = 500,
  holdDuration = 8000
}: TypewriterOptions) => {
  const [displayText, setDisplayText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

  const getNextPhraseIndex = useCallback(() => {
    // Fisher-Yates shuffle for true randomness
    const nextIndex = Math.floor(Math.random() * (phrases.length - 1));
    return nextIndex >= currentPhraseIndex ? nextIndex + 1 : nextIndex;
  }, [phrases.length, currentPhraseIndex]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    
    const typeText = (text: string, currentIndex: number, onComplete: () => void) => {
      if (currentIndex <= text.length) {
        setDisplayText(text.slice(0, currentIndex));
        
        if (currentIndex === text.length) {
          onComplete();
        } else {
          const delay = Math.random() * (maxSpeed - minSpeed) + minSpeed;
          timeout = setTimeout(() => typeText(text, currentIndex + 1, onComplete), delay);
        }
      }
    };

    const unTypeText = (text: string, currentIndex: number, onComplete: () => void) => {
      if (currentIndex >= 0) {
        setDisplayText(text.slice(0, currentIndex));
        
        if (currentIndex === 0) {
          onComplete();
        } else {
          const delay = Math.random() * (maxSpeed - minSpeed) + minSpeed;
          timeout = setTimeout(() => unTypeText(text, currentIndex - 1, onComplete), delay);
        }
      }
    };

    const startCycle = () => {
      const currentPhrase = phrases[currentPhraseIndex];
      setIsTyping(true);

      // Type the phrase
      typeText(currentPhrase, 0, () => {
        // Hold the text
        timeout = setTimeout(() => {
          // Untype the phrase
          unTypeText(currentPhrase, currentPhrase.length, () => {
            // Move to next phrase
            setCurrentPhraseIndex(getNextPhraseIndex());
          });
        }, holdDuration);
      });
    };

    timeout = setTimeout(startCycle, startDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentPhraseIndex, phrases, minSpeed, maxSpeed, startDelay, holdDuration, getNextPhraseIndex]);

  return { displayText, isTyping };
};
import React from 'react';
import { Brain, Target, Heart } from 'lucide-react';
import { useTypewriter } from '../hooks/useTypewriter';
import Navbar from './Navbar';
import Hero from './hero/Hero';
import Button from './Button';
import FeatureCard from './FeatureCard';
import Heading from './typography/Heading';

const aiRoles = [
  "Life Coach",
  "Job Advisor",
  "Buddy",
  "Guide",
  "Nutritionist"
];

interface HomepageProps {
  onGetStarted: () => void;
  onMeetAIs: () => void;
}

const Homepage: React.FC<HomepageProps> = ({ onGetStarted, onMeetAIs }) => {
  const { displayText: roleText, isTyping } = useTypewriter({
    phrases: aiRoles,
    minSpeed: 70,
    maxSpeed: 150,
    startDelay: 800,
    holdDuration: 8000
  });

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar onGetStarted={onGetStarted} onMeetAIs={onMeetAIs} />
      <main className="pt-16"> {/* Add padding-top to account for fixed navbar */}
        <Hero 
          roleText={roleText}
          isTyping={isTyping}
          onGetStarted={onGetStarted}
          onMeetAIs={onMeetAIs}
        />

        {/* Features Section */}
        <section id="features" className="py-20 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            <Heading level={2} className="text-center text-gray-900 mb-12">
              Why Choose liveguide.ai?
            </Heading>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <FeatureCard
                icon={<Brain className="w-6 h-6" />}
                title="Personalized Guidance"
                description="Get tailored advice and strategies based on your unique goals and preferences."
              />
              <FeatureCard
                icon={<Target className="w-6 h-6" />}
                title="Goal Tracking"
                description="Set, track, and achieve your goals with our intelligent progress monitoring system."
              />
              <FeatureCard
                icon={<Heart className="w-6 h-6" />}
                title="Holistic Approach"
                description="Balance personal growth, career development, and well-being with our comprehensive coaching."
              />
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20 bg-blue-600 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto text-center">
            <Heading level={2} className="text-white/90 mb-6">
              Ready to Transform Your Life?
            </Heading>
            <p className="text-xl text-blue-100/80 mb-8 max-w-2xl mx-auto">
              Join thousands of others who have already started their journey to success.
            </p>
            <div className="flex flex-col gap-4 max-w-xs mx-auto">
              <Button 
                variant="primary" 
                size="lg" 
                onClick={onGetStarted}
                className="w-full justify-center"
              >
                Start Your Journey
                <Brain className="ml-2 w-5 h-5" />
              </Button>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Homepage;
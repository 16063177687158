export const minimal = {
  button: {
    primary: 'bg-black text-white font-medium py-2 px-4 rounded hover:bg-gray-800 transition-colors duration-200',
    secondary: 'bg-gray-100 text-gray-900 font-medium py-2 px-4 rounded hover:bg-gray-200 transition-colors duration-200',
    outline: 'border border-gray-900 text-gray-900 font-medium py-2 px-4 rounded hover:bg-gray-50 transition-colors duration-200'
  },
  card: {
    wrapper: 'bg-white border border-gray-200 rounded p-4',
    header: 'pb-3 border-b border-gray-100',
    body: 'py-3',
    footer: 'pt-3 border-t border-gray-100'
  },
  input: {
    base: 'w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-gray-500 transition-colors duration-200',
    error: 'border-red-300 focus:border-red-500',
    success: 'border-green-300 focus:border-green-500'
  }
};
import React from 'react';
import { Target } from 'lucide-react';

interface AdaIconProps {
  className?: string;
}

const AdaIcon: React.FC<AdaIconProps> = ({ className = "w-6 h-6" }) => (
  <Target className={className} />
);

export default AdaIcon;
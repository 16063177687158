import React, { createContext, useState, useCallback } from 'react';
import { templates, TemplateType } from '../styles/templates';

interface TemplateContextType {
  currentTemplate: TemplateType;
  template: typeof templates.modern;
  setTemplate: (template: TemplateType) => void;
}

export const TemplateContext = createContext<TemplateContextType | null>(null);

export const TemplateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentTemplate, setCurrentTemplate] = useState<TemplateType>('modern');

  const setTemplate = useCallback((template: TemplateType) => {
    setCurrentTemplate(template);
  }, []);

  const value = {
    currentTemplate,
    template: templates[currentTemplate],
    setTemplate
  };

  return (
    <TemplateContext.Provider value={value}>
      {children}
    </TemplateContext.Provider>
  );
};
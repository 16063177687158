import { useState, useEffect } from 'react';
import { LeadService } from '../services/leadService';

export function useLeadTracking() {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;
  const INITIAL_RETRY_DELAY = 1000;

  useEffect(() => {
    let mounted = true;
    let retryTimeout: NodeJS.Timeout;

    const initializeSession = async () => {
      if (!mounted) return;

      try {
        setLoading(true);
        setError(null);

        // Get existing or generate new session ID
        let currentSessionId = localStorage.getItem('lead_session_id');
        
        if (!currentSessionId) {
          currentSessionId = LeadService.generateSessionId();
          localStorage.setItem('lead_session_id', currentSessionId);
        }

        // Verify or create lead
        const existingLead = await LeadService.getLeadBySessionId(currentSessionId);
        
        // If lead exists and is converted, generate new session
        if (existingLead?.converted_user_id) {
          currentSessionId = LeadService.generateSessionId();
          localStorage.setItem('lead_session_id', currentSessionId);
        }

        // Create lead if it doesn't exist or was converted
        if (!existingLead || existingLead.converted_user_id) {
          await LeadService.createLead({
            session_id: currentSessionId,
            selected_goal_ids: []
          });
        }

        if (mounted) {
          setSessionId(currentSessionId);
          setError(null);
          setRetryCount(0);
        }
        
      } catch (err) {
        console.error('Lead session initialization error:', err);
        
        if (mounted) {
          setError(err instanceof Error ? err.message : 'Failed to initialize session');
          
          if (retryCount < MAX_RETRIES) {
            const delay = INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
            retryTimeout = setTimeout(() => {
              if (mounted) {
                setRetryCount(prev => prev + 1);
              }
            }, delay);
          }
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    initializeSession();

    return () => {
      mounted = false;
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, [retryCount]);

  const updateGoalSelections = async (goalIds: number[]) => {
    if (!sessionId) return;

    try {
      setError(null);
      await LeadService.updateLead(sessionId, goalIds);
    } catch (err) {
      console.error('Goal update error:', err);
      setError(err instanceof Error ? err.message : 'Failed to update goals');
    }
  };

  const convertToUser = async (userId: string) => {
    if (!sessionId) return;

    try {
      setError(null);
      await LeadService.convertLeadToUser(sessionId, userId);
      localStorage.removeItem('lead_session_id');
      setSessionId(null);
    } catch (err) {
      console.error('Lead conversion error:', err);
      setError(err instanceof Error ? err.message : 'Failed to convert lead to user');
    }
  };

  return {
    sessionId,
    loading,
    error,
    updateGoalSelections,
    convertToUser
  };
}
import React, { useState, useEffect } from 'react';
import HeroBackground from './HeroBackground';
import HeroContent from './HeroContent';

interface HeroProps {
  roleText: string;
  isTyping: boolean;
  onGetStarted: () => void;
  onMeetAIs: () => void;
}

const heroImages = [
  "https://mcimageassets.s3.amazonaws.com/lc/biplane.png",
  "https://mcimageassets.s3.amazonaws.com/lc/boat.png"
];

const Hero: React.FC<HeroProps> = ({ roleText, isTyping, onGetStarted, onMeetAIs }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const nextImageIndex = (currentImageIndex + 1) % heroImages.length;

  useEffect(() => {
    heroImages.forEach(src => {
      const img = new Image();
      img.src = src;
    });

    const interval = setInterval(() => {
      setCurrentImageIndex(prev => (prev + 1) % heroImages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="relative h-screen flex items-center overflow-hidden">
      <HeroBackground 
        currentImage={heroImages[currentImageIndex]}
        nextImage={heroImages[nextImageIndex]}
      />
      <HeroContent 
        roleText={roleText}
        isTyping={isTyping}
        onGetStarted={onGetStarted}
        onMeetAIs={onMeetAIs}
      />
    </section>
  );
};

export default Hero;
import { v4 as uuidv4 } from 'uuid';
import { supabase } from '../lib/supabase';
import type { Lead, CreateLeadDTO } from '../lib/types';

export class LeadService {
  static generateSessionId(): string {
    return uuidv4();
  }

  static async createLead(data: CreateLeadDTO): Promise<Lead> {
    try {
      // First, check if lead exists
      const { data: existingLead, error: fetchError } = await supabase
        .from('leads')
        .select('*')
        .eq('session_id', data.session_id)
        .maybeSingle();

      if (fetchError) {
        console.error('Error checking existing lead:', fetchError);
        throw new Error(`Failed to check existing lead: ${fetchError.message}`);
      }

      // If lead exists and is not converted, return it
      if (existingLead && !existingLead.converted_user_id) {
        return existingLead;
      }

      // If lead exists but is converted, generate new session
      if (existingLead && existingLead.converted_user_id) {
        const newSessionId = this.generateSessionId();
        return this.createLead({
          ...data,
          session_id: newSessionId
        });
      }

      // Create new lead
      const { data: newLead, error: insertError } = await supabase
        .from('leads')
        .insert([{
          session_id: data.session_id,
          selected_goal_ids: data.selected_goal_ids || [],
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }])
        .select()
        .single();

      if (insertError) {
        console.error('Error creating lead:', insertError);
        throw new Error(`Failed to create lead: ${insertError.message}`);
      }

      if (!newLead) {
        throw new Error('No data returned from lead creation');
      }

      return newLead;
    } catch (error) {
      console.error('Error in createLead:', error);
      throw error;
    }
  }

  static async updateLead(sessionId: string, goalIds: number[]): Promise<Lead> {
    try {
      console.log('Updating lead with session ID:', sessionId, 'Goals:', goalIds);
      
      const { data: lead, error } = await supabase
        .from('leads')
        .update({
          selected_goal_ids: goalIds,
          updated_at: new Date().toISOString()
        })
        .eq('session_id', sessionId)
        .select()
        .single();

      if (error) {
        console.error('Error updating lead:', error);
        throw new Error(`Failed to update lead: ${error.message}`);
      }

      if (!lead) {
        throw new Error('No data returned from lead update');
      }

      console.log('Lead updated successfully:', lead);
      return lead;
    } catch (error) {
      console.error('Error in updateLead:', error);
      throw error;
    }
  }

  static async getLeadBySessionId(sessionId: string): Promise<Lead | null> {
    try {
      const { data: lead, error } = await supabase
        .from('leads')
        .select('*')
        .eq('session_id', sessionId)
        .maybeSingle();

      if (error) {
        console.error('Error fetching lead:', error);
        throw new Error(`Failed to fetch lead: ${error.message}`);
      }

      return lead;
    } catch (error) {
      console.error('Error in getLeadBySessionId:', error);
      throw error;
    }
  }

  static async convertLeadToUser(sessionId: string, userId: string): Promise<void> {
    try {
      const { error } = await supabase
        .from('leads')
        .update({
          converted_user_id: userId,
          updated_at: new Date().toISOString()
        })
        .eq('session_id', sessionId);

      if (error) {
        console.error('Error converting lead:', error);
        throw new Error(`Failed to convert lead: ${error.message}`);
      }
    } catch (error) {
      console.error('Error in convertLeadToUser:', error);
      throw error;
    }
  }
}
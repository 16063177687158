import React from 'react';
import { Check, X } from 'lucide-react';
import type { Category, Suggestion } from '../../types';

interface SelectedGoalsProps {
  selectedSuggestions: Suggestion[];
  categories: Category[];
  onClear: () => void;
}

const SelectedGoals: React.FC<SelectedGoalsProps> = ({
  selectedSuggestions,
  categories,
  onClear,
}) => {
  if (selectedSuggestions.length === 0) return null;

  return (
    <div className="mb-6 bg-white rounded-xl p-4 shadow-md">
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center gap-2 text-sm text-gray-600">
          <Check className="w-4 h-4 text-blue-600" />
          <span>Selected Goals ({selectedSuggestions.length})</span>
        </div>
        <button 
          onClick={onClear}
          className="text-gray-400 hover:text-gray-600 p-1 rounded"
        >
          <X className="w-4 h-4" />
        </button>
      </div>
      <div className="space-y-2">
        {selectedSuggestions.map((suggestion, index) => {
          const category = categories.find(c => c.id === suggestion.categoryId);
          if (!category) return null;
          return (
            <div key={index} className="flex items-center gap-2 text-sm">
              <category.icon className={`w-3 h-3 ${category.color}`} />
              <span className="text-gray-600">{suggestion.text}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectedGoals;
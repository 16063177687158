import React, { useEffect, useRef } from 'react';
import { ConversationService } from '../../services/conversationService';

interface ConversationWidgetProps {
  agentId: string;
}

const ConversationWidget: React.FC<ConversationWidgetProps> = ({ agentId }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    // Clear any existing content
    containerRef.current.innerHTML = '';

    // Create widget element
    const widget = document.createElement('elevenlabs-convai');
    widget.setAttribute('agent-id', agentId);
    
    // Append widget to container
    containerRef.current.appendChild(widget);

    // Initialize conversation
    const initConversation = async () => {
      try {
        const conversation = await ConversationService.startSession({
          signedUrl: 'your-signed-url', // Replace with actual signed URL
          clientTools: {
            triggerBrowserAlert: async (parameters) => {
              alert(parameters.message);
            }
          }
        });

        // Add event listener for widget messages
        widget.addEventListener('message', (event: any) => {
          console.log('Widget message:', event.detail);
        });

      } catch (error) {
        console.error('Error initializing conversation:', error);
      }
    };

    initConversation();

    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = '';
      }
    };
  }, [agentId]);

  return (
    <div 
      ref={containerRef}
      className="conversation-widget min-h-[200px] w-full"
    />
  );
};

export default ConversationWidget;
import { supabase } from '../lib/supabase';
import type { Category } from '../types';
import { Heart, Target, Brain, Leaf, Users } from 'lucide-react';

export interface Goal {
  id: number;
  category: string;
  description: string;
}

const categoryIcons = {
  'Health & Wellness': Heart,
  'Career Growth': Target,
  'Personal Development': Brain,
  'Spiritual Growth': Leaf,
  'Relationships': Users
};

const categoryColors = {
  'Health & Wellness': 'text-rose-500',
  'Career Growth': 'text-blue-500',
  'Personal Development': 'text-purple-500',
  'Spiritual Growth': 'text-green-500',
  'Relationships': 'text-pink-500'
};

export class GoalService {
  static async getAllGoals(): Promise<Goal[]> {
    try {
      const { data, error } = await supabase
        .from('goals')
        .select('*')
        .order('category, description');

      if (error) {
        throw error;
      }

      return data || [];
    } catch (err) {
      console.error('Error fetching goals:', err);
      throw err;
    }
  }

  static transformGoalsToCategories(goals: Goal[]): Category[] {
    // Group goals by category
    const groupedGoals = goals.reduce((acc, goal) => {
      if (!acc[goal.category]) {
        acc[goal.category] = [];
      }
      acc[goal.category].push(goal.description);
      return acc;
    }, {} as Record<string, string[]>);

    // Transform into categories array
    return Object.entries(groupedGoals).map(([categoryName, suggestions], index) => ({
      id: index + 1,
      title: categoryName,
      icon: categoryIcons[categoryName as keyof typeof categoryIcons],
      color: categoryColors[categoryName as keyof typeof categoryColors],
      priority: index,
      suggestions
    }));
  }
}
import { supabase } from '../lib/supabase';
import type { CreateUserDTO } from '../lib/types';

export class OnboardingService {
  static async completeOnboarding(userData: CreateUserDTO) {
    try {
      // First check if user exists
      const { data: existingUser } = await supabase
        .from('users')
        .select('*')
        .eq('email', userData.email)
        .single();

      if (existingUser) {
        // Update existing user
        const { data: updatedProfile, error: updateError } = await supabase
          .from('users')
          .update({
            name: userData.name,
            selected_goals: userData.selected_goals || [],
            answers: userData.answers || {},
            updated_at: new Date().toISOString()
          })
          .eq('email', userData.email)
          .select()
          .single();

        if (updateError) {
          console.error('User update error:', updateError);
          throw new Error(`Failed to update profile: ${updateError.message}`);
        }

        return updatedProfile;
      } else {
        // Create new user
        const { data: profile, error: insertError } = await supabase
          .from('users')
          .insert([{
            name: userData.name,
            email: userData.email,
            selected_goals: userData.selected_goals || [],
            answers: userData.answers || {},
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          }])
          .select()
          .single();

        if (insertError) {
          console.error('Onboarding error:', insertError);
          throw new Error(`Failed to create profile: ${insertError.message}`);
        }

        return profile;
      }
    } catch (err) {
      console.error('Error in completeOnboarding:', err);
      throw err;
    }
  }
}
import React from 'react';

interface HeadingProps {
  level?: 1 | 2;
  children: React.ReactNode;
  className?: string;
}

const Heading: React.FC<HeadingProps> = ({ level = 1, children, className = '' }) => {
  const baseStyles = 'font-display tracking-tight';
  const styles = {
    1: 'text-4xl sm:text-5xl lg:text-6xl font-bold',
    2: 'text-2xl sm:text-3xl lg:text-4xl font-semibold'
  };

  const Tag = `h${level}` as keyof JSX.IntrinsicElements;
  
  return (
    <Tag className={`${baseStyles} ${styles[level]} ${className}`}>
      {children}
    </Tag>
  );
};

export default Heading;
export const classic = {
  button: {
    primary: 'bg-blue-600 text-white font-semibold py-2 px-6 rounded-md hover:bg-blue-700 active:bg-blue-800 transition-colors duration-200',
    secondary: 'bg-gray-200 text-gray-800 font-semibold py-2 px-6 rounded-md hover:bg-gray-300 active:bg-gray-400 transition-colors duration-200',
    outline: 'border-2 border-blue-600 text-blue-600 font-semibold py-2 px-6 rounded-md hover:bg-blue-50 transition-colors duration-200'
  },
  card: {
    wrapper: 'bg-white rounded-md shadow border border-gray-200',
    header: 'px-6 py-4 border-b border-gray-200 font-semibold',
    body: 'px-6 py-4',
    footer: 'px-6 py-4 border-t border-gray-200 bg-gray-50'
  },
  input: {
    base: 'w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200',
    error: 'border-red-300 focus:ring-red-500 focus:border-red-500',
    success: 'border-green-300 focus:ring-green-500 focus:border-green-500'
  }
};
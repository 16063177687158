import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import Button from './Button';

interface NavbarProps {
  onGetStarted: () => void;
  onMeetAIs: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onGetStarted, onMeetAIs }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-md border-b border-gray-200">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex items-center">
            <img 
              src="https://mcimageassets.s3.amazonaws.com/lc/ANAI.png" 
              alt="liveguide.ai logo" 
              className="w-8 h-8 object-contain"
            />
            <span className="ml-2 text-xl font-display font-bold text-gray-900">liveguide.ai</span>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:block">
            <div className="flex items-center space-x-8">
              <Button variant="secondary" size="sm" onClick={onMeetAIs}>
                Meet Our AIs
              </Button>
              <Button variant="primary" size="sm" onClick={onGetStarted}>
                Get Started
              </Button>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="flex md:hidden">
            <Button
              variant="secondary"
              size="sm"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2"
            >
              {isMenuOpen ? (
                <X className="w-6 h-6" />
              ) : (
                <Menu className="w-6 h-6" />
              )}
            </Button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-white border-b border-gray-200">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                onMeetAIs();
                setIsMenuOpen(false);
              }}
              className="w-full justify-start"
            >
              Meet Our AIs
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                onGetStarted();
                setIsMenuOpen(false);
              }}
              className="w-full"
            >
              Get Started
            </Button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
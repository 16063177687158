import React from 'react';
import { X } from 'lucide-react';
import UserSetupForm from './UserSetupForm';
import UserSetupSummary from './UserSetupSummary';
import type { UserAnswer } from '../../lib/types';

interface UserSetupModalProps {
  selectedGoals: string[];
  selectedStyles: string[];
  questionnaireAnswers: UserAnswer[];
  onBack: () => void;
  onComplete: () => void;
}

const UserSetupModal: React.FC<UserSetupModalProps> = ({
  selectedGoals,
  selectedStyles,
  questionnaireAnswers,
  onBack,
  onComplete
}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={onBack} />
      <div className="relative w-full max-w-2xl bg-white rounded-2xl shadow-2xl m-4">
        <button
          onClick={onBack}
          className="absolute top-4 right-4 p-2 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100"
        >
          <X className="w-5 h-5" />
        </button>
        
        <div className="p-8">
          <div className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900">Create Your Profile</h2>
            <p className="mt-2 text-gray-600">
              Almost there! Let's set up your profile to personalize your AI coaching experience.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <UserSetupForm 
              onComplete={onComplete}
              selectedGoals={selectedGoals.map(Number)}
              questionnaireAnswers={questionnaireAnswers}
            />
            <UserSetupSummary
              selectedGoals={selectedGoals}
              selectedStyles={selectedStyles}
              questionnaireAnswers={questionnaireAnswers}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSetupModal;